import axios from 'axios';
import env from '../Common/Env';

/**
 * サイトお知らせ一覧取得
 */
const getSiteInfo = async(page,parPage) => {
  return axios.get(`${env.api_domain}/api/v1/siteinfo`, {
    params: {
      page: page,
      parPage: parPage
    }
  })
    .then(resp => {
      return resp
    }).catch(err => {
      throw err
    })
}

/**
 * サイトお知らせ詳細取得
 */
const getSiteInfoDetail = async(id) => {
  return axios.get(`${env.api_domain}/api/v1/siteinfo/${id}`)
    .then(resp => {
      return resp
    }).catch(err => {
      throw err
    })
}

/**
 * サイトお知らせ詳細登録
 */
const registSiteInfoDetail = async(title, content, createdAt) => {
  return axios.post(`${env.api_domain}/api/v1/siteinfo`, {
    title: title,
    content: content,
    createdAt: createdAt
  },{
    headers: {'Authorization': `bearer ` + localStorage.getItem("token")} 
  })
    .then(resp => {
      return resp
    }).catch(err => {
      throw err
    })
}

/**
 * サイトお知らせ詳細編集
 */
const editSiteInfoDetail = async(id, title, content, createdAt) => {
  return axios.put(`${env.api_domain}/api/v1/siteinfo`, {
    id: id,
    title: title,
    content: content,
    createdAt: createdAt
  },{
    headers: {'Authorization': `bearer ` + localStorage.getItem("token")} 
  })
    .then(resp => {
      return resp
    }).catch(err => {
      throw err
    })
}

/**
 * サイトお知らせ詳細削除
 */
const deleteSiteInfoDetail = async(id) => {
  return axios.delete(`${env.api_domain}/api/v1/siteinfo`, {
    headers: {'Authorization': `bearer ` + localStorage.getItem("token")},
    data: { id: id }
  })
    .then(resp => {
      return resp
    }).catch(err => {
      throw err
    })
}

export { getSiteInfo, getSiteInfoDetail, registSiteInfoDetail, editSiteInfoDetail, deleteSiteInfoDetail }
  