// 環境変数
const env = 'production'
// const env = 'dev'

const domain = env => {
    if(env === 'staging') {
        return 'https://'
    }

    if(env === 'production') {
        return 'https://api.bloom-system.tech'
    }
    return 'http://localhost:30003'
}

export default {
    api_domain: domain(env),
    env: env,
}
