import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const propTypes = {
  nextPageAction: PropTypes.func,
  beforePageAction: PropTypes.func
};

/**
 * ページネーションコンポーネント
 */
class PagenatePart extends Component {

  /**
   * イベントハンドラ
   */
  nextPageAction(){
    let page = Number(this.props.carrentPage) * Number(this.props.parPage)
    if(page < this.props.totalCount){
      return this.props.nextPageAction();
    }
  }
  beforePageAction(){
    let page = Number(this.props.carrentPage) * Number(this.props.parPage)
    if(0 < page-this.props.parPage){
      return this.props.beforePageAction();
    }
  }


  /**
   * レンダリング
   */
  render() {
    return (
      <Pagination size="sm" aria-label="Page navigation example" className="my-3">
        <PaginationItem>
          <PaginationLink previous 
            onClick={() => this.beforePageAction()}
          />
        </PaginationItem>
        <PaginationItem>
          <PaginationLink>
            {this.props.carrentPage}ページ目 ({((this.props.carrentPage-1)*this.props.parPage)+1}~{this.props.carrentPage*this.props.parPage}件表示/{this.props.totalCount}件中)
          </PaginationLink>
        </PaginationItem>
        <PaginationItem>
          <PaginationLink next
            onClick={() => this.nextPageAction()}
          />
        </PaginationItem>
      </Pagination>
    );
  }
}
PagenatePart.propTypes = propTypes;
export default PagenatePart;


