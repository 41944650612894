import React, { Component } from 'react';
import { Row,Container,Col } from 'reactstrap';

/**
 * 4列画像カラムコンポーネント
 */
class FourColumnImageSection extends Component {

  render() {
    return (
      <Container fluid={true} className="wrap bg-second" id="works">
        <Container className="py-5">
          <Row className="py-5">
            <Col xs="12" md="12">
              <h2 className="display-4 en-new-spirit ">{this.props.title}</h2>
              <hr className="dots"/>
              <Row className="mt-5">
              {this.props.lists.map((image, index) => (
                <Col xs="6" sm="6" md="3 p-0" key={index}>
                    <img src={`${process.env.PUBLIC_URL}${image}`} className="img-fluid" alt="mv" />
                </Col>
              )) }
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}
export default FourColumnImageSection;