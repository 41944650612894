import React, { Component } from 'react';
import { Row,Container,Col } from 'reactstrap';

/**
 * 3カラムカードリストコンポーネント
 */
class ThreeColumnCardSection extends Component {

  render() {
    return (
      <Container className="py-5">
        <Row className="py-5">
          <Col xs="12" md="12">
            <p className="text-orange">{this.props.subTitle}</p>
            <h2 className="display-4 en-new-spirit ">{this.props.title}</h2>
            <hr className="dots"/>
            <Row className="mt-5">
              {this.props.lists.map((list, index) => (
                <Col xs="12" sm="4" md="4 px-5 pt-0" className="" key={index}>
                  <img src={`${process.env.PUBLIC_URL}${list.image}`} alt="mv" className="text-center mx-5 mb-2"/>
                  <h3 className="font-weight-bold">{list.title}</h3>
                  <p>{list.data}</p>
                </Col>
              )) }
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default ThreeColumnCardSection;