import React from 'react';
import { Alert } from 'reactstrap';

/**
 * メッセージパーツ
 */
const MessageInfo = (props) => {
    const message = props.message   
    const color = props.color   
    if(props.message){
      return (
        <Alert 
          className="mx-0 my-2 p-1" 
          color={color}
        >
          <p className="mb-0 text-center">{message}</p>
        </Alert>
      );
    } else {
      return null;
    }
}
export default MessageInfo;