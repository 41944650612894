import React from 'react';
import { Row,Container,Col } from 'reactstrap';
import { SITEINFO } from '../Common/SiteInfo'
import DtListPart from '../Parts/DtListPart';

/**
 * フッターコンポーネント
 */
const FooterSection = (props) => {
  return (
    <Container fluid={true} className="position-relative bg-ddark text-white">
      <Container className="py-5 text-left">
        <Row>
          <Col xs="12" md="4 mx-auto">
            <h2 className="">{props.siteConfig.site_name}</h2>
            <hr/>
            <p className="">{props.siteConfig.site_discription}</p>
            <p>{props.siteConfig.site_catch}</p>
          </Col>
          <Col xs="12" md="4 mx-auto">
            <DtListPart
              lists={SITEINFO.SITE_MENU}
            />
          </Col>
          <Col xs="12" md="4 mx-auto">
            <DtListPart
              lists={SITEINFO.SITE_SUBMENU}
            />
            <a href="/login" className="text-black"><p className="text-black">login</p></a>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default FooterSection;