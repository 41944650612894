import React, { useState } from 'react';
import { SITEINFO } from '../Common/SiteInfo'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

/**
 * ナビゲーションバーコンポーネント
 */
const NavbarPart = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar 
        color={props.siteConfig.header_font_dark}
        light={!props.siteConfig.header_dark}
        dark={props.siteConfig.header_dark}
        expand="md"
        className={props.siteConfig.header_fixed ? "fixed-top bg-ddark" : ""}
      >
        <NavbarBrand href="/">{props.siteConfig.site_name}</NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            {SITEINFO.SITE_MENU.map((menu, index) => (
              <NavItem key={index}>
                <NavLink href={menu.URL}>{menu.TITLE}</NavLink>
              </NavItem>
            )) }
          </Nav>
        </Collapse>
        {props.siteConfig.header_dark ? <p className={"mb-0 text-light"}>{props.siteConfig.site_discription}</p>
        : <p className={"mb-0 text-dark"}>{props.siteConfig.site_discription}</p>}
      </Navbar>
    </div>
  );
}

export default NavbarPart;