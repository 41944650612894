import React, { Component } from 'react';
import { SITEINFO } from './Common/SiteInfo'
import NavbarSection from './Sections/NavbarSection'
import MainView from './Sections/MainView'
import FourColumnImageSection from './Sections/FourColumnImageSection'
import TabSection from './Sections/TabSection'
import FormSection from './Sections/FormSection'
import ThreeColumnCardSection from './Sections/ThreeColumnCardSection'
import RightColumn2rowsSection from './Sections/RightColumn2rowsSection'
import RightColumnSection from './Sections/RightColumnSection'
import EndMessageSection from './Sections/EndMessageSection'
import FooterSection from './Sections/FooterSection'
import Copyright from './Sections/Copyright';
import Loader from './Parts/Loader';
import { getSiteInfo } from './Lib/siteInfo'
import { getInfo } from './Lib/Info'
import { getSiteConfig } from './Lib/config'

/**
 * トップページコンポーネント
 */
class Index extends Component {

    /**
     * 初期化
     */
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            message: false,
            color: false,
            siteConfig: {},
            infoList: [],
            siteInfoList: [],
            workList: [],
            WorkFlowList: [],
            resultList: [],
            serviceList: [],
            isLoading: true
        };
    }

    /**
     * マウント
     */
    componentDidMount(){
        this.setState({
            workList: SITEINFO.WORKLIST,
            WorkFlowList: SITEINFO.WORKFLOW,
            resultList: SITEINFO.RESULTLIST,
            serviceList: SITEINFO.SERVICE
        })
        this.getInfoAction(1,5)
        this.getSiteInfoAction(1,5)
        this.getSiteConfigAction()
    }

    getInfoAction(page,parPage){
        getInfo(page,parPage)
        .then(async (resp) => {
          if(resp.status === 200){
            this.setState({
              totalCount: resp.data.count,
              infoList: resp.data.rows,
              isLoading: false
            })
          }
        }).catch( err => {
            let message = err.message
            return this.setState({ 
                message: message,
                color: "danger",
                isLoading : false
            })
        })
    }

    getSiteInfoAction(page,parPage){
        getSiteInfo(page,parPage)
        .then(async (resp) => {
          if(resp.status === 200){
            this.setState({
              totalCount: resp.data.count,
              siteInfoList: resp.data.rows,
              isLoading: false
            })
          }
        }).catch( err => {
            let message = err.message
            return this.setState({ 
                message: message,
                color: "danger",
                isLoading : false
            })
        })
    }

    getSiteConfigAction(){
        getSiteConfig()
        .then(async (resp) => {
          if(resp.status === 200){
            resp.data.header_dark ? resp.data.header_font_dark = "dark" : resp.data.header_font_dark = "light"
            this.setState({
              siteConfig: resp.data
            })
          }
        }).catch( () => {})
    }

    render() {
        return (
            <>
                <NavbarSection
                    siteConfig={this.state.siteConfig}
                />
                <Loader loading={this.state.isLoading}/>
                <MainView
                    siteConfig={this.state.siteConfig}
                />
                <TabSection
                    title="Informations"
                    tab={["お知らせ","サイト情報"]}
                    infoList={this.state.infoList}
                    siteInfoList={this.state.siteInfoList}
                />
                <FourColumnImageSection
                    title="Works"
                    lists={this.state.workList}
                />
                <ThreeColumnCardSection
                    title="Work Flow"
                    subTitle="求められるニーズに答え全体的なアプローチを提案します"
                    lists={this.state.WorkFlowList}
                />
                <RightColumn2rowsSection
                    title="Results"
                    subTitle="主な実績"
                    lists={this.state.resultList}
                />
                <RightColumnSection
                    title="Service"
                    subTitle="学習、モックアップ、ノウハウ蓄積の為の制作物"
                    lists={this.state.serviceList}
                />
                <FormSection/>
                <EndMessageSection/>
                <FooterSection
                    siteConfig={this.state.siteConfig}
                />
                <Copyright
                    siteConfig={this.state.siteConfig}
                />

            </>
        );
    }
}

export default Index;
