import React from 'react';
import { Row,Container,Col } from 'reactstrap';

/**
 * エンドメッセージコンポーネント
 */
const EndMessageSection = () => {
  return (
    <Container fluid={true} className="position-relative my-5">
      <Container className="">
        <Row>
          <Col xs="12" md="8 mx-auto" className="py-5">
            <h2 className="mb-5">私たちはプログラミングが好きで多くの言語、フレームワークを経験しています</h2>
            <p className="">サーバーサイド言語として、php,node,python</p>
            <p className="">クライアントサイド言語として、JavaScript,jQuery,Angular,React,Vue</p>
            <p className="">フレームワークとして、Laravel,CodeIgniter,Yii</p>
            <p className="">CMSとして、Wordpress,Magento,ECCUBE</p>
            <p className="">上記に当てはまる開発案件、そうでない案件についてもぜひご相談をいただければ幸いです。</p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default EndMessageSection;