import React, { Component } from 'react';
import {Container,Row,Col, FormGroup, Label,
  CustomInput, CardHeader,CardBody,Card,Input,Button} from 'reactstrap';
import DashboardMenuSection from './Sections/DashboardMenuSection';
import Loader from './Parts/Loader';
import Messageinfo from './Parts/MessageInfo'
import { Message } from './Common/Message';
import { getSiteConfig,editConfig } from './Lib/config'
import { authToken } from './Common/Lib'

var createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL;

/**
 * 設定コンポーネント
 */
class DashboardSetting extends Component {

  /**
   * 初期化
   */
  constructor(props) {
    super(props);
    this.state = {
      menuTab: {
        home: false,
        info: false,
        site: false,
        blog: false,
        setting: true,
      },
      siteConfig: {},
      isLoading: true
    };
  }

  /**
   * 読み込み時
   */
  componentDidMount () {
    authToken(localStorage.getItem("token"))
    .then(async (resp) => {
      if(resp.status === 200){
        this.setState({
          isLoading: false
        })
      }
    }).catch( () => {
      this.props.history.push("/login")
    })
    this.getSiteConfig()
  }

  /**
   * イベントハンドラ
   */
  menuClick(tab){
    return this.props.history.push({ 
      pathname: `/dashboard/${tab}`
    });
  }
  /**
   * サイト設定取得
   */
  getSiteConfig(){
    getSiteConfig()
    .then(async (resp) => {
      if(resp.status === 200){
        this.setState({
          siteConfig: resp.data,
          isLoading: false
        })
      }
    }).catch( err => {
        let message = err.message
        return this.setState({ 
            message: message,
            color: "danger",
            isLoading : false
        })
    })
  }
  /**
   * スイッチイベント
   */
  onChangeSwitch(target,value){
    let setting = this.state.siteConfig
    if(target === "darkMode"){
      setting.header_dark = !value
    }
    if(target === "fixedHeader"){
      setting.header_fixed = !value
    }
    if(target === "mainView"){
      setting.mainview_full = !value
    }
    
    this.setState({siteConfig: setting})
  }
  /**
   * チェンジイベント
   */
  onChangeForm(target,value){
    let setting = this.state.siteConfig
    if(target === "title"){
      setting.site_name = value
    }
    if(target === "catch"){
      setting.site_catch = value
    }
    if(target === "discription"){
      setting.site_discription = value
    }
    this.setState({siteConfig: setting})
  }
  /**
   * 画像変更変更イベント
   */
  changeImage(img){
    var files = img.target.files;
    let config = this.state.siteConfig
    config.mainview_url = files[0].name
    config.mainview_path =  files.length===0 ? "" : createObjectURL(files[0]);
    this.setState(
      { img: files[0],
        siteConfig: config,
        message: false
      }
    )
  }
  /**
   * クリアフォーム
   */
  onClickCancel(){
    this.getSiteConfig()
  }
  /**
   * 更新イベント
   */
  onClickEdit(){
    this.setState({isLoading: true})
      this.edit(this.state)
  }
  edit(params){
    editConfig(params)
    .then(async (resp) => {
      if(resp.status === 200){
        this.setState({
          message: Message.resultEdit,
          color: "info",
          isLoading : false
        })
      }
    }).catch( err => {
        let message = err.message
        return this.setState({ 
            message: message,
            color: "danger",
            isLoading : false
        })
    })
  }

  /**
   * レンダリング
   */
  render() {
    return (
      <div className="bg-darkblue">
        <Container className="py-5">
          <Loader loading={this.state.isLoading}/>
          <Row className="justify-content-center">
            <DashboardMenuSection
              menuTab={this.state.menuTab}
              menuClick={(num) => { this.menuClick(num); }}
            />
            <Col xs={12} className="text-left">
              <h3 className="text-left text-white mb-3 left-border">サイト設定</h3>
              <Card className="dark-shadow">
                <CardHeader className="bg-darkblue text-white">
                </CardHeader>
                  <CardBody
                    className="bg-grayblue text-left"
                  >
                  <h3>基本設定</h3>
                  <hr/>
                  <FormGroup className="form-group row">
                    <Label htmlFor="mail" className="col-sm-4 col-form-label text-left">タイトル</Label>
                    <div className="col-sm-8">
                      <Input 
                        type="text"
                        className="form-control" 
                        onChange={e => this.onChangeForm("title",e.target.value)}
                        value={this.state.siteConfig.site_name}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="form-group row">
                    <Label htmlFor="mail" className="col-sm-4 col-form-label text-left">キャッチフレーズ</Label>
                    <div className="col-sm-8">
                      <Input 
                        type="text"
                        className="form-control" 
                        onChange={e => this.onChangeForm("catch",e.target.value)}
                        value={this.state.siteConfig.site_catch}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="form-group row">
                    <Label htmlFor="mail" className="col-sm-4 col-form-label text-left">サイト説明</Label>
                    <div className="col-sm-8">
                      <Input 
                        type="text"
                        className="form-control" 
                        onChange={e => this.onChangeForm("discription",e.target.value)}
                        value={this.state.siteConfig.site_discription}
                      />
                    </div>
                  </FormGroup>

                  <h3 className="mt-5">ビジュアル設定</h3>
                  <hr/>
                  <FormGroup>
                    <Label for="checkbox">Header</Label>
                    <CustomInput 
                      type="switch" 
                      id="darkmodeSwitch" 
                      name="customSwitch"
                      checked={this.state.siteConfig.header_dark}
                      onChange={() => { this.onChangeSwitch("darkMode",this.state.siteConfig.header_dark); }}
                      label="ダークモード" />
                  </FormGroup>
                  <FormGroup>
                    <CustomInput 
                      type="switch" 
                      id="fixedHeaderSwitch" 
                      name="customSwitch"
                      checked={this.state.siteConfig.header_fixed}
                      onChange={() => { this.onChangeSwitch("fixedHeader",this.state.siteConfig.header_fixed); }}
                      label="ヘッダー位置固定" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="checkbox">Main View</Label>
                    <CustomInput 
                      type="switch" 
                      id="mainViewSwitch" 
                      name="mainViewSwitch"
                      checked={this.state.siteConfig.mainview_full}
                      onChange={() => { this.onChangeSwitch("mainView",this.state.siteConfig.mainview_full); }}
                      label="メインビュー全画面表示" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="checkbox">Main View header image</Label>
                    <Row>
                      <Col xs="12" sm="4">
                        <img src={this.state.siteConfig.mainview_path} alt="" width="65px" className=""/>
                      </Col>
                      <Col xs="12" sm="8">
                        <CustomInput 
                          id="file"
                          type="file" 
                          label={this.state.siteConfig.mainview_url}
                          accept="image/*"
                          multiple
                          onChange={e => this.changeImage(e)}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <Col xs="12" className="my-5 text-right">
                    <Messageinfo
                      message={this.state.message}
                      color={this.state.color}
                    />
                    <Button
                      type="button"
                      size="sm"
                      className="bg-yellow m-1"
                      onClick={() => this.onClickCancel()}
                    >クリア</Button>
                    <Button
                      type="button"
                      size="sm"
                      className="bg-blue m-1"
                      onClick={() => this.onClickEdit()}
                    >更新</Button>
                  </Col>
                  </CardBody>
                </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default DashboardSetting;
