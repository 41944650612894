import React, { useState } from 'react';
import { Container, Row,TabContent, TabPane, Nav, NavItem, NavLink, Col } from 'reactstrap';
import ListPart from '../Parts/ListPart'
import classnames from 'classnames';

/**
 * タブコンポーネント
 * props
 *  infoList = []
 *  siteInfoList = []
 * 
 */
const TabSection = (props) => {
  const [activeTab, setActiveTab] = useState('1');
  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
  const infoList = props.infoList
  const siteInfoList = props.siteInfoList
  const title = props.title
  const tabList = props.tab

  return (
    <Container className="py-5">
        <Row className="py-5"> 
            <Col xs={12} md={10} className="mx-auto">
                <h2 className="display-4 en-new-spirit">{title}</h2>
                <hr className="dots"/>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '1' })}
                            onClick={() => { toggle('1'); }}
                        >{tabList[0]}</NavLink>
                        </NavItem>
                        <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => { toggle('2'); }}
                    >{tabList[1]}</NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1" className="mt-3">
                        <Row>
                            <Col sm="12">
                                <ListPart
                                    lists={infoList}
                                />
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2" className="mt-3">
                        <Row>
                            <Col sm="12">
                            <ListPart
                                    lists={siteInfoList}
                                />
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
            </Col>
        </Row>
    </Container>
  );
}

export default TabSection;