import React, { Component } from 'react';
import {Container,Row,Col, ListGroupItem, ListGroupItemHeading,
  Button, ListGroupItemText} from 'reactstrap';
import DashboardMenuSection from './Sections/DashboardMenuSection';
import PagenatePart from './Parts/PagenatePart';
import Loader from './Parts/Loader';
import ModalArea from './Parts/ModalArea';
import Messageinfo from './Parts/MessageInfo'
import { Message } from './Common/Message';
import { getBlog, deleteBlog } from './Lib/blog'
import { authToken } from './Common/Lib'

/**
 * ダッシュボードブログコンポーネント
 */
class DashboardBlog extends Component {

  /**
   * 初期化
   */
  constructor(props) {
    super(props);
    this.state = {
      menuTab: {
        home: false,
        info: false,
        site: false,
        blog: true,
        setting: false,
      },
      page: 1,
      parPage: 20,
      totalCount: 0,
      blogList: [],
      isLoading: true
    };
  }

  /**
   * 読み込み時
   */
  componentDidMount () {
    authToken(localStorage.getItem("token"))
    .then(async (resp) => {
      if(resp.status === 200){
        this.setState({
          isLoading: false
        })
      }
    }).catch( () => {
      this.props.history.push("/login")
    })
    this.getBlogAction(this.state.page,this.state.parPage)
  }

  /**
   * イベントハンドラ
   */
  menuClick(tab){
    return this.props.history.push({ 
      pathname: `/dashboard/${tab}`
    });
  }
  nextPageAction(){
    let page = this.state.page + 1
    this.setState({
      page: page
    })
    this.getBlogAction(page,this.state.parPage)
    window.scrollTo(0, 0)
  }
  beforePageAction(){
    let page = this.state.page - 1
    this.setState({
      page: page
    })
    this.getBlogAction(page,this.state.parPage)
    window.scrollTo(0, 0)
  }
  /**
   * ブログ一覧取得
   */
  getBlogAction(page,parPage){
    getBlog(page,parPage)
    .then(async (resp) => {
      if(resp.status === 200){
        this.setState({
          totalCount: resp.data.count,
          blogList: resp.data.rows,
          isLoading: false
        })
      }
    }).catch( err => {
        let message = err.message
        return this.setState({ 
            message: message,
            color: "danger",
            isLoading : false
        })
    })
  }
  /**
   *ブログ編集
   */
  onClickEdit(id){
    console.log(id)
    this.props.history.push({ 
      pathname: `/dashboard/blog/edit/${id}`
    });
  }
  /**
   * ブログ削除
   */
  onClickDelete(id){
    console.log(id)
    this.setState({isLoading: true})
    deleteBlog(id)
    .then(async (resp) => {
      if(resp.status === 200){
        this.setState({
          message: Message.resultDelete,
          color: "info",
          isLoading : false
        })
        this.getBlogAction(this.state.page,this.state.parPage)
      }
    }).catch( err => {
        let message = err.message
        return this.setState({ 
            message: message,
            color: "danger",
            isLoading : false
        })
    })
  }

  /**
   * レンダリング
   */
  render() {
    return (
      <div className="bg-darkblue">
        <Container className="py-5">
          <Loader loading={this.state.isLoading}/>
          <Row className="justify-content-center">
            <DashboardMenuSection
              menuTab={this.state.menuTab}
              menuClick={(num) => { this.menuClick(num); }}
            />
            <Col xs={12} className="text-right">
              <h3 className="text-left text-white mb-3 left-border">ブログ一覧</h3>
              <div className="p-2 bg-grayblue dark-shadow">
                <Messageinfo
                  message={this.state.message}
                  color={this.state.color}
                />
                <Button
                  type="button"
                  size="sm"
                  className="bg-prime mb-3"
                  onClick={() => this.onClickEdit(0)}
                >新規作成</Button>
                {this.state.blogList.map((list, index) => (
                  <ListGroupItem key={index} className="radius-s mb-1">
                    <Row>
                    <Col xs={12} sm={10}>
                    <ListGroupItemHeading className="text-left">{list.createdAt}</ListGroupItemHeading>
                    <ListGroupItemText className="text-left mb-0">{list.post_title}</ListGroupItemText>
                    </Col>
                    <Col xs={12} sm={2} className="text-right">
                      <Button
                        type="button"
                        size="sm"
                        className="bg-yellow m-1"
                        onClick={() => this.onClickEdit(list.id)}
                      >編集</Button>
                      <ModalArea
                        label="削除"
                        modalTitle="削除します。よろしいですか？"
                        modalBody={list.post_title}
                        onClick={() => this.onClickDelete(list.id)}
                      />
                    </Col>
                    </Row>
                  </ListGroupItem>
                )) }
                <PagenatePart
                  carrentPage={this.state.page}
                  parPage={this.state.parPage}
                  totalCount={this.state.totalCount}
                  nextPageAction={() => { this.nextPageAction(); }}
                  beforePageAction={() => { this.beforePageAction(); }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default DashboardBlog;
