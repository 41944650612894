import React, { Component } from 'react';
import {Container,Row,Col,FormGroup,Label,Input,Button } from 'reactstrap';
import { getSiteInfoDetail, editSiteInfoDetail,registSiteInfoDetail } from './Lib/siteInfo'
import { Message } from './Common/Message';
import Messageinfo from './Parts/MessageInfo'
import Loader from './Parts/Loader';

/**
 * サイト情報編集コンポーネント
 */
class EditSiteInfo extends Component {

  /**
   * 初期化
   */
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: "サイト情報登録",
      id: this.props.match.params.id,
      title: "",
      content: "",
      createdAt: "",
      message: "",
      color: "",
      isLoading: true
    };
  }

  /**
   * 読み込み時
   */
  componentDidMount () {
    if(this.state.id !== '0'){
      getSiteInfoDetail(this.state.id)
      .then(async (resp) => {
        if(resp.status === 200){
          this.setState({
            pageTitle: "サイト情報編集",
            title: resp.data.title,
            content: resp.data.content,
            createdAt: resp.data.createdAt,
            isLoading: false
          })
        }
      }).catch( err => {
          let message = err.message
          return this.setState({ 
              message: message,
              color: "danger",
              isLoading : false
          })
      })
    }
    return this.setState({ 
      isLoading : false
  })
  }

  /**
   * イベントハンドラ
   */
  onChangeTitle(value){
    this.setState({
      title: value
    })
  }
  onChangeContent(value){
    this.setState({
      content: value
    })
  }
  onChangeDate(value){
    this.setState({
      createdAt: value
    })
  }
  onClickCancel(){
    this.props.history.goBack()
  }
  onClickEdit(){
    this.setState({isLoading: true})
    if(this.state.id !== '0'){
      this.edit(this.state.id,this.state.title,this.state.content,this.state.createdAt)
    } else {
      this.regist(this.state.title,this.state.content,this.state.createdAt)
    }
  }
  edit(id,title,content,createdAt){
    editSiteInfoDetail(id,title,content,createdAt)
    .then(async (resp) => {
      if(resp.status === 200){
        this.setState({
          message: Message.resultEdit,
          color: "info",
          isLoading : false
        })
      }
    }).catch( err => {
        let message = err.message
        return this.setState({ 
            message: message,
            color: "danger",
            isLoading : false
        })
    })
  }
  regist(title,content,createdAt){
    registSiteInfoDetail(title,content,createdAt)
    .then(async (resp) => {
      if(resp.status === 200){
        this.setState({
          message: Message.resultEdit,
          color: "info",
          isLoading : false
        })
      }
    }).catch( err => {
        let message = err.message
        return this.setState({ 
            message: message,
            color: "danger",
            isLoading : false
        })
    })
  }


  /**
   * レンダリング
   */
  render() {
    return (
      <div className="bg-darkblue">
        <Container className="py-5">
        <Loader loading={this.state.isLoading}/>
        <Row className="justify-content-center">
          <Col xs="12" className="mb-5">
            <h2>{this.state.pageTitle}</h2>
            <hr/>
          </Col>
          <Col xs="12" className="">
            <FormGroup row className=" justify-content-center">
              <Col xs={12} className="mb-3">
                <Messageinfo
                  message={this.state.message}
                  color={this.state.color}
                />
                <div className="form-group row">
                  <Label className="col-sm-4 col-form-label text-left">投稿日</Label>
                  <div className="col-sm-8">
                    <Input
                      type="date"
                      name="date"
                      id="exampleDate"
                      placeholder="date placeholder"
                      onChange={e => this.onChangeDate(e.target.value)}
                      value={this.state.createdAt}
                    />  
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="mail" className="col-sm-4 col-form-label text-left">タイトル</label>
                  <div className="col-sm-8">
                    <input 
                      type="text"
                      className="form-control" 
                      placeholder="お知らせタイトル"
                      onChange={e => this.onChangeTitle(e.target.value)}
                      value={this.state.title}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="mail" className="col-sm-4 col-form-label text-left">コンテンツ</label>
                  <div className="col-sm-8">
                    <textarea 
                      type="text"
                      className="form-control" 
                      placeholder="お知らせ内容"
                      rows="5"
                      onChange={e => this.onChangeContent(e.target.value)}
                      value={this.state.content}
                    />
                  </div>
                </div>
              </Col>
            </FormGroup>
          </Col>
          <Col xs="12" className="mb-5">
            <Button
              type="button"
              size="sm"
              className="bg-yellow m-1"
              onClick={() => this.onClickCancel()}
            >キャンセル</Button>
            <Button
              type="button"
              size="sm"
              className="bg-blue m-1"
              onClick={() => this.onClickEdit()}
            >更新</Button>
          </Col>
        </Row>
      </Container>
      </div>
    );
  }
}

export default EditSiteInfo;
