import React, { Component } from 'react';
import { postContact } from '../Common/Lib'
import Messageinfo from '../Parts/MessageInfo'

/**
 * フォームコンポーネント
 */
class FormSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            message: false,
            color: false,
            name: "",
            mail: "",
            content: ""
        };
    }

    /**
     * イベントハンドラ
     */
    onChangeName(name){
        this.setState({name: name})
    }
    onChangeMail(mail){
        this.setState({mail: mail})
    }
    onChangeContent(content){
        this.setState({content: content})
    }
    submit(){
        let params = new URLSearchParams();
        params.append('name', `${this.state.name}`);
        params.append('mail', `${this.state.mail}`);
        params.append('content', `${this.state.content}`);
    
        postContact(params)
        .then(async (resp) => {
            return this.setState({ 
                message: resp.data.message,
                color: "info",
                isLoading : false
            })
        }).catch( err => {
            let message = err.message
            return this.setState({ 
                message: message,
                color: "danger",
                isLoading : false
            })
        })
    }

    render() {
        return (
        <section className="bg-form bg-prime" id="contact">
            <div className="bg-partition-top"/>
            <div className="container pt-5 text-dark">
            <div className="row">
                <div className="col-12 col-md-10 mb-5 mx-auto">
                    <p className="text-white">私達は現在新しいプロジェクト制作を<span className="text-green font-weight-bold">受付中</span>です</p>
                    <h2 className="text-white mx-auto display-4 text-center mb-5 en-new-spirit ">Lets Discuss Your Project</h2>
                    <p className="text-center mb-5 text-white">お問い合わせ、お見積もりは無料です。<br/>
                    ぜひお客様のアイディア、構想についてご相談ください。</p>
                    <form>
                        <div className="form-group row">
                            <label htmlFor="name" className="col-sm-4 col-form-label text-left text-white">会社名・お名前</label>
                            <div className="col-sm-8">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="name" 
                                    name="name" 
                                    placeholder="株式会社example"
                                    onChange={e => this.onChangeName(e.target.value)}
                                    value={this.state.name}
                                />
                            </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="mail" className="col-sm-4 col-form-label text-left text-white">メールアドレス</label>
                                <div className="col-sm-8">
                                <input 
                                    type="email" 
                                    className="form-control" 
                                    id="mail" name="url" placeholder="example@gmail.com"
                                    onChange={e => this.onChangeMail(e.target.value)}
                                    value={this.state.mail}
                                />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="message" className="col-sm-4 col-form-label text-left text-white">お問い合わせ内容</label>
                                <div className="col-sm-8">
                                    <textarea 
                                        className="form-control" 
                                        id="message" name="message" rows="5"
                                        onChange={e => this.onChangeContent(e.target.value)}
                                        value={this.state.content}
                                    ></textarea>
                                </div>
                            </div>
                            <div className="form-group row">
                            <div className="col-sm-12">
                                <Messageinfo
                                    message={this.state.message}
                                    color={this.state.color}
                                />
                                <button 
                                    type="button" 
                                    className="btn radius px-5 py-2 bg-white text-orange"
                                    onClick={() => this.submit()}
                                >
                                    送信する<i className="fa fa-envelope ml-2"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div className="bg-partition-bottom"/>
        </section>
        );
    }
}

export default FormSection;