import React, { Component } from 'react';
import { Card,NavItem,NavLink,Col } from 'reactstrap';

/**
 * ダッシュボードメニューコンポーネント
 */
class DashboardMenu extends Component {

  /**
   * イベントハンドラ
   */
  menuClick(num){
    return this.props.menuClick(num);
  }

  /**
   * レンダリング
   */
  render() {
    return (
      <Col xs="12" className="mb-5">
        <h2 className="grad"><i className="fas fa-tachometer-alt mr-3"></i>dashboard</h2>
        <hr className="dots mb-5"/>
        <Col xs={12} className="mb-3">
          <Card className="p-2 bg-grayblue">
            <NavItem className="nav nav-pills nav-justified flex-column flex-sm-row">
              <NavLink 
                className="nav-item nav-link"
                active={this.props.menuTab.home}
                onClick={() => this.menuClick('')}
              >HOME</NavLink>
              <NavLink 
                className="nav-item nav-link"
                active={this.props.menuTab.info}
                onClick={() => this.menuClick("info")}
              >お知らせ</NavLink>
              <NavLink 
                className="nav-item nav-link"
                active={this.props.menuTab.site}
                onClick={() => this.menuClick("site")}
              >サイト情報</NavLink>
              <NavLink 
                className="nav-item nav-link" 
                active={this.props.menuTab.blog}
                onClick={() => this.menuClick("blog")}
              >ブログ</NavLink>
              <NavLink 
                className="nav-item nav-link" 
                active={this.props.menuTab.setting}
                onClick={() => this.menuClick("setting")}
              >設定</NavLink>
            </NavItem>
          </Card>
        </Col>
      </Col>
    );
  }
}
export default DashboardMenu;