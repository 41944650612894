import axios from 'axios';
import env from '../Common/Env';

/**
 * ブログ一覧取得
 */
const getBlog = async(page,parPage) => {
  return axios.get(`${env.api_domain}/api/v1/post`, {
    params: {
      page: page,
      parPage: parPage
    }
  })
    .then(resp => {
      return resp
    }).catch(err => {

      throw err
    })
}

/**
 * ブログ詳細取得
 */
const getBlogDetail = async(id) => {
  return axios.get(`${env.api_domain}/api/v1/post/${id}`)
    .then(resp => {
      return resp
    }).catch(err => {
      throw err
    })
}
  
/**
 * ブログ詳細登録
 */
const registBlogDetail = async(title, content, createdAt) => {
  return axios.post(`${env.api_domain}/api/v1/post`, {
    post_title: title,
    post_content: content,
    createdAt: createdAt
  },{
    headers: {'Authorization': `bearer ` + localStorage.getItem("token")} 
  })
    .then(resp => {
      return resp
    }).catch(err => {
      throw err
    })
  }
  
/**
 * ブログ詳細編集
 */
const editBlogDetail = async(id, title, content, createdAt) => {
  return axios.put(`${env.api_domain}/api/v1/post`, {
    id: id,
    post_title: title,
    post_content: content,
    createdAt: createdAt
  },{
    headers: {'Authorization': `bearer ` + localStorage.getItem("token")} 
  })
    .then(resp => {
      return resp
    }).catch(err => {
      throw err
    })
}
  

/**
 * ブログ削除
 */
const deleteBlog = async(id) => {
  return axios.delete(`${env.api_domain}/api/v1/post`, {
    headers: {'Authorization': `bearer ` + localStorage.getItem("token")},
    data: { id: id }
  })
    .then(resp => {
      return resp
    }).catch(err => {
      throw err
    })
}

export { getBlog, getBlogDetail, registBlogDetail, editBlogDetail, deleteBlog }
  