import axios from 'axios';
import env from './Env';

/**
 * 問い合わせ送信
 */
const postContact = async(params) => {
    return axios.post(`https://bloom-system.tech/thanks.php`, params)
      .then(resp => {
        return resp
      }).catch(err => {
        throw err
      })
}

const getText = async(params) => {
  return axios.get(`https://bloom-system.tech/getText.php?value=${params}`)
    .then(resp => {
      return resp
    }).catch(err => {
      throw err
    })
}

/**
 * サインイン
 */
const signIn = async(params) => {
  return axios.get(`${env.api_domain}/api/v1/user`, {
    params: {
      account: params.loginId,
      password: params.password
    }
  })
    .then(resp => {
      return resp
    }).catch(err => {
      throw err
    })
}

/**
 * 認証
 */
const authToken = async(token) => {
  return axios.get(`${env.api_domain}/api/v1/user/token`, { 
      headers: {'Authorization': `bearer ` + token}
    })
    .then(resp => {
      return resp
    }).catch(err => {
      throw err
    })
}

export { postContact, getText, signIn, authToken }
