import React, { Component } from 'react';
import { Container,Col,Button } from 'reactstrap';

/**
 * メインビューコンポーネント
 */
class MainView extends Component {
  constructor(props) {
    super(props);
  }
  render() {

    const fullView = {
      background: '#aaccff',
      paddingTop: '30vh',
      height: '100vh',
      backgroundSize: 'cover',
      background: `url(${this.props.siteConfig.mainview_path}) center/cover fixed`,
    };
    const hulfView = {
      background: '#171717',
      paddingTop: '5vh',
      paddingBottom: '5vh',
      backgroundSize: 'cover',
    };

    const viewStyle = this.props.siteConfig.mainview_full

    return (
      <Container fluid={true} className="wrap p-0">
        <div className="justify-content-center" style={viewStyle ? fullView : hulfView}>
          <Col xs="12" md="12" className="my-5 pt-5 position-relative">
              <h1 className="grad pb-2 en-new-spirit">{this.props.siteConfig.site_name}</h1>
              <p className="text-secondary en-new-spirit">{this.props.siteConfig.site_catch}</p>
          </Col>
        </div>
        <div className="shapedividers_com-4026" />
      </Container>
    );
  }
}
export default MainView;