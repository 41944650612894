import React from 'react';
import Loader from 'react-loader-spinner'

/**
 * ローダーパーツ
 */
export default class App extends React.Component {

    render() {
        if(this.props.loading){
            return (
                <div className="loader">
                    <Loader
                        type="Rings"
                        color="#1b7eff"
                    />
                    <p className="text-center mb-0 loader-color"><strong>読み込み中です</strong></p>
                </div>
            );
        } else{
            return null;
        }
    }
}
