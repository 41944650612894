import React from 'react';

/**
 * ToTOP
 */
const Totop = () => {
  return (
    <div className="totop">
      <a href ="/#"><img src={`${process.env.PUBLIC_URL}/pagetop.png`} alt="totop"/></a>
    </div>
  )
}
export default Totop;