import React, { Component } from 'react';
import {Container,Row,Col, ListGroupItem, ListGroupItemHeading,
  Button, ListGroupItemText,Card} from 'reactstrap';
import PagenatePart from './Parts/PagenatePart';
import DashboardMenuSection from './Sections/DashboardMenuSection';
import ModalArea from './Parts/ModalArea';
import Loader from './Parts/Loader';
import Messageinfo from './Parts/MessageInfo'
import { Message } from '../component/Common/Message';
import { getInfo,deleteInfoDetail } from './Lib/Info'
import { authToken } from './Common/Lib'

/**
 * お知らせコンポーネント
 */
class DashboardInfo extends Component {

  /**
   * 初期化
   */
  constructor(props) {
    super(props);
    this.state = {
      menuTab: {
        home: false,
        info: true,
        site: false,
        blog: false,
        setting: false,
      },
      page: 1,
      parPage: 20,
      totalCount: 0,
      infoList: [],
      isLoading: true
    };
  }

  /**
   * 読み込み時
   */
  componentDidMount () {
    authToken(localStorage.getItem("token"))
    .then(async (resp) => {
      if(resp.status === 200){
        this.setState({
          isLoading: false
        })
      }
    }).catch( () => {
      this.props.history.push("/login")
    })
    this.getInfoAction(this.state.page,this.state.parPage)
  }

  /**
   * イベントハンドラ
   */
  menuClick(tab){
    return this.props.history.push({ 
      pathname: `/dashboard/${tab}`
    });
  }
  nextPageAction(){
    let page = this.state.page + 1
    this.setState({
      page: page
    })
    this.getInfoAction(page,this.state.parPage)
    window.scrollTo(0, 0)
  }
  beforePageAction(){
    let page = this.state.page - 1
    this.setState({
      page: page
    })
    this.getInfoAction(page,this.state.parPage)
    window.scrollTo(0, 0)
  }
  /**
   * お知らせ一覧取得
   */
  getInfoAction(page,parPage){
    getInfo(page,parPage)
    .then(async (resp) => {
      if(resp.status === 200){
        this.setState({
          totalCount: resp.data.count,
          infoList: resp.data.rows,
          isLoading: false
        })
      }
    }).catch( err => {
        let message = err.message
        return this.setState({ 
            message: message,
            color: "danger",
            isLoading : false
        })
    })
  }
  onClickEdit(id){
    this.props.history.push({ 
      pathname: `/dashboard/info/edit/${id}`
    });
  }
  onClickDelete(id){
    console.log(id)
    this.setState({isLoading: true})
    deleteInfoDetail(id)
    .then(async (resp) => {
      if(resp.status === 200){
        this.setState({
          message: Message.resultDelete,
          color: "info",
          isLoading : false
        })
        this.getInfoAction(this.state.page,this.state.parPage)
      }
    }).catch( err => {
        let message = err.message
        return this.setState({ 
            message: message,
            color: "danger",
            isLoading : false
        })
    })
  }

  /**
   * レンダリング
   */
  render() {
    return (
      <div className="bg-darkblue">
        <Container className="py-5">
          <Loader loading={this.state.isLoading}/>
          <Row className="justify-content-center">
            <DashboardMenuSection
              menuTab={this.state.menuTab}
              menuClick={(num) => { this.menuClick(num); }}
            />
            <Col xs={12} className="text-right">
              <h3 className="text-left text-white mb-3 left-border">お知らせ一覧</h3>
              <div className="p-2 bg-grayblue dark-shadow">
                <Messageinfo
                  message={this.state.message}
                  color={this.state.color}
                />
                <Button
                  type="button"
                  size="sm"
                  className="bg-prime mb-3"
                  onClick={() => this.onClickEdit(0)}
                >新規作成</Button>
                {this.state.infoList.map((list, index) => (
                  <ListGroupItem key={index} className="radius-s mb-1">
                    <Row>
                      <Col xs={12} sm={10}>
                      <ListGroupItemHeading className="text-left">{list.createdAt}</ListGroupItemHeading>
                      <ListGroupItemText className="text-left mb-0">{list.title}</ListGroupItemText>
                      </Col>
                      <Col xs={12} sm={2} className="text-right">
                        <Button
                          type="button"
                          size="sm"
                          className="bg-yellow m-1"
                          onClick={() => this.onClickEdit(list.id)}
                        >編集</Button>
                        <ModalArea
                          label="削除"
                          modalTitle="削除します。よろしいですか？"
                          modalBody={list.title}
                          onClick={() => this.onClickDelete(list.id)}
                        />
                      </Col>
                    </Row>
                  </ListGroupItem>
                )) }
                <PagenatePart
                  carrentPage={this.state.page}
                  parPage={this.state.parPage}
                  totalCount={this.state.totalCount}
                  nextPageAction={() => { this.nextPageAction(); }}
                  beforePageAction={() => { this.beforePageAction(); }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default DashboardInfo;
