import React, { Component } from 'react';
import {Container,Row,Col,FormGroup,Input,Card} from 'reactstrap';
import NavbarSection from './Sections/NavbarSection'
import { getText } from './Common/Lib'
import { getSiteConfig } from './Lib/config'


/**
 * コンテンツコンポーネント
 */
class Contents extends Component {

  /**
   * 初期化
   */
  constructor(props) {
    super(props);
    this.state = {
      siteConfig: {},
      keyword: "",
      words: []
    };
    window.scrollTo(0, 0)
  }

  /**
   * 読み込み時
   */
  componentDidMount () {
    this.onSearch("")
    this.getSiteConfigAction()
  }

  /**
   * イベントハンドラ
   */
  onChangeVal(value){
    console.log(this.state.keyword)
    this.setState({
      keyword: value
    })
    this.onSearch(value)
  }
  getSiteConfigAction(){
    getSiteConfig()
    .then(async (resp) => {
      if(resp.status === 200){
        resp.data.header_dark ? resp.data.header_font_dark = "dark" : resp.data.header_font_dark = "light"
        this.setState({
          siteConfig: resp.data
        })
      }
    }).catch( () => {})
  }
  /**
   * 検索結果取得
   */
  onSearch(value){
    getText(value)
    .then(async (resp) => {
        return this.setState({ 
          words: resp.data
        })
    }).catch( err => {
        let message = err.message
        return this.setState({ 
            message: message,
            color: "danger",
            isLoading : false
        })
    })
  }

  /**
   * レンダリング
   */
  render() {
    return (
      <>
        <NavbarSection
          siteConfig={this.state.siteConfig}
        />
        <Container className="mt-5 py-5">
          <Row className="justify-content-center">
            <Col xs="12" className="mb-5">
              <h2>IT関係・プロジェクトでよく使う英語</h2>
              <hr/>
              <FormGroup row className="mt-3 justify-content-center">
                <Col sm={6}>
                  <p>日本語を入力</p>
                  <Input
                    type="text"
                    value={this.state.keyword}
                    autoFocus={true}
                    onChange={e => this.onChangeVal(e.target.value)}
                  />
                </Col>
              </FormGroup>
            </Col>
            <h3>結果</h3>
            <Col xs="12" className="mb-5 bg-light py-5">
            {this.state.words.map((word, index) => (
                <Row key={index} className="text-left">
                  <Col xs="12" sm="6">
                    <Card className="p-2">
                        <p className="mb-0">{word.english}</p>
                    </Card>
                  </Col>
                  <Col xs="12" sm="6">
                    <Card className="p-2">
                        <p className="mb-0">{word.japanese}</p>
                    </Card>
                  </Col>
                </Row>
            )) }
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Contents;
