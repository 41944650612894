import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

/**
 * モーダルコンポーネント
 */
const ModalArea = (props) => {
  const {
    label,
    modalTitle,
    modalBody,
    onClick
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const doClick = () => {
    setModal(!modal)
    onClick()
  }

  return (
    <>
      <Button
        type="button"
        size="sm"
        className="bg-dark m-1"
        onClick={toggle}
      >{label}</Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
        <ModalBody>{modalBody}</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle} size="sm">いいえ</Button>
          <Button 
            color="secondary" 
            onClick={toggle}
            onClick={() => doClick()}
            size="sm"
            className="bg-dark">はい</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ModalArea;