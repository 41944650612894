import React from 'react';
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';

/**
 * リストコンポーネント
 * props
 *  lists = []
 */
const ListPart = (props) => {
  const lists = props.lists
  return (
    <ListGroup>
      {lists.map((list, index) => (
        <ListGroupItem key={index}>
          <ListGroupItemHeading className="text-left">{list.createdAt}</ListGroupItemHeading>
          <ListGroupItemText className="text-left mb-0">{list.title}</ListGroupItemText>
        </ListGroupItem>
      )) }
    </ListGroup>
  );
}

export default ListPart;