import React, { Component } from 'react';
import {Container,Col,Button} from 'reactstrap';
import { signIn } from './Common/Lib'
import Messageinfo from './Parts/MessageInfo'
import { getSiteConfig } from './Lib/config'

/**
 * ログインコンポーネント
 */
class Login extends Component {

  /**
   * 初期化
   */
  constructor(props) {
    super(props);
    this.state = {
      siteConfig: {},
      loginId: "",
      password: "",
      message: "",
      color: "",
    };
    window.scrollTo(0, 0)
  }

  /**
   * 読み込み時
   */
  componentDidMount () {
    this.getSiteConfigAction()
  }

  /**
   * イベントハンドラ
   */
  onChangeLoginId(value){
    this.setState({
      loginId: value
    })
  }
  onChangePassword(value){
    this.setState({
      password: value
    })
  }
  getSiteConfigAction(){
    getSiteConfig()
    .then(async (resp) => {
      if(resp.status === 200){
        this.setState({
          siteConfig: resp.data
        })
      }
    }).catch( () => {})
}

  /**
   * ログイン
   */
  submit(){
    signIn(this.state)
    .then(async (resp) => {
      if(resp.status === 200){
        localStorage.setItem("token", resp.data.token)
        return this.props.history.push('/dashboard')
      }
      else return 
    }).catch( err => {
        let message = err.message
        return this.setState({ 
            message: message,
            color: "danger",
            isLoading : false
        })
    })
  }

  /**
   * レンダリング
   */
  render() {
    return (
      <Container fluid={true} className="wrap p-0">
      <div className="wrapper justify-content-center vh-pt2">
        <Col xs="11 mx-auto" md="5 mx-auto" lg="4 mx-auto" xl="3 mx-auto" className="my-5 py-5 bg-trans">
          <h1 className="grad pb-2 en-new-spirit">{this.state.siteConfig.site_name}</h1>
          <p className="text-secondary en-new-spirit">{this.state.siteConfig.site_catch}</p>
          <hr className="mb-5"/>
          <div className="form-group row mb-3">
            <label htmlFor="name" className="col-md-4 col-form-label text-right">Login ID</label>
            <div className="col-md-8">
              <input
                autoFocus={true}
                type="email" 
                className="form-control" 
                placeholder=""
                onChange={e => this.onChangeLoginId(e.target.value)}
                value={this.state.loginId}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="mail" className="col-md-4 col-form-label text-right">PASSWORD</label>
            <div className="col-md-8">
              <input 
                type="password" 
                className="form-control" 
                minLength={8}
                maxLength={8}
                onChange={e => this.onChangePassword(e.target.value)}
                value={this.state.password}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-12">
              <Messageinfo
                message={this.state.message}
                color={this.state.color}
              />
              <Button
                type="button" 
                href="#contact"
                className="btn radius px-5 py-2 mt-5 bg-white text-orange"
                onClick={() => this.submit()}
                >
                  SIGN IN<i className="fa fa-sign-in-alt ml-2"></i>
              </Button>
            </div>
          </div>
        </Col>
      </div>
    </Container>
    );
  }
}

export default Login;
