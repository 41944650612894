import React from 'react';
import { BrowserRouter, Route, Switch  } from 'react-router-dom'
import Auth from './component/Parts/Auth';
import Index from './component/Index';
import Contents from './component/Contents';
import Dashboard from './component/Dashboard';
import Login from './component/Login';
import DashboardInfo from './component/DashboardInfo';
import EditInfo from './component/EditInfo';
import DashboardSite from './component/DashboardSite';
import EditSiteInfo from './component/EditSiteInfo';
import DashboardBlog from './component/DashboardBlog';
import EditBlog from './component/EditBlog';
import DashboardSetting from './component/DashboardSetting';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Index} />
        <Route exact path="/login" component={Login} />
        <Auth>
          <Switch>
            <Route exact path="/contents" component={Contents} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/dashboard/info" component={DashboardInfo} />
            <Route exact path="/dashboard/info/edit/:id" component={EditInfo} />
            <Route exact path="/dashboard/site" component={DashboardSite} />
            <Route exact path="/dashboard/siteinfo/edit/:id" component={EditSiteInfo} />
            <Route exact path="/dashboard/blog" component={DashboardBlog} />
            <Route exact path="/dashboard/blog/edit/:id" component={EditBlog} />
            <Route exact path="/dashboard/setting" component={DashboardSetting} />
          </Switch>
        </Auth>
      </Switch>
    </BrowserRouter>
    );
}

export default App;

