import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'

/**
 * ログイン認証コンポーネント
 */
class Auth extends Component {
  constructor(){
    super()
    this.state = { loginState: false }
  }

  UNSAFE_componentWillMount () {
    let token = localStorage.getItem('token');
    if(token !== null && token !== ""){
      this.setState({ loginState : true })
    }
  }

  render() {
    const Auth = this.state.loginState ? this.props.children : <Redirect to={'/login'}/>
    return (
      <div>
        {Auth}
      </div>
    );
  }
}


export default Auth

