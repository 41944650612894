import React from 'react';
import { Row,Container,Col } from 'reactstrap';
import Totop from '../Parts/Totop';

/**
 * ナビゲーションバーコンポーネント
 */
const Copyright = (props) => {
  return (
    <Container fluid={true} className="bg-black">
      <Container className="">
        <Row>
          <Col xs="12" md="12" className="pt-3">
            <p className="text-white">Copyright © {props.siteConfig.site_name}</p>
          </Col>
        </Row>
      </Container>
      <Totop/>
    </Container>
  );
}

export default Copyright;