import React, { Component } from 'react';
import {Container,Row,Col, Card, CardHeader,CardBody,Button } from 'reactstrap';
import Loader from './Parts/Loader';
import DashboardMenuSection from './Sections/DashboardMenuSection';
import { authToken } from './Common/Lib'
import { getSiteConfig } from './Lib/config'


/**
 * ダッシュボードコンポーネント
 */
class Dashboard extends Component {

  /**
   * 初期化
   */
  constructor(props) {
    super(props);
    this.state = {
      siteConfig: {},
      menuTab: {
        home: true,
        info: false,
        site: false,
        blog: false,
        setting: false,
      },
      isLoading: true
    };
  }

  /**
   * 読み込み時
   */
  componentDidMount () {
    authToken(localStorage.getItem("token"))
    .then(async (resp) => {
      if(resp.status === 200){
        this.setState({
          isLoading: false
        })
      }
    }).catch( () => {
      this.props.history.push("/login")
    })
    getSiteConfig()
    .then(async (resp) => {
      if(resp.status === 200){
        this.setState({
          siteConfig: resp.data
        })
      }
    }).catch( () => {
      this.props.history.push("/login")
    })
  }

  /**
   * イベントハンドラ
   */
  menuClick(tab){
    return this.props.history.push({ 
      pathname: `/dashboard/${tab}`
    });
  }
  signOut(){
    localStorage.clear()
    return this.props.history.push(`/login`)    
  }

  /**
   * レンダリング
   */
  render() {
    return (
      <div className="bg-darkblue">
        <Container className="py-5">
          <Loader loading={this.state.isLoading}/>
          <Row className="justify-content-center">
            <DashboardMenuSection
              menuTab={this.state.menuTab}
              menuClick={(num) => { this.menuClick(num); }}
            />
            <Col xs={12} className="mb-5">
              <Card className="dark-shadow">
                <CardHeader className="bg-darkblue text-white">
                  </CardHeader>
                  <CardBody
                    className="bg-grayblue text-left"
                  >
                    <p>サイト名：{this.state.siteConfig.site_name}</p>
                    <p>キャッチフレーズ：{this.state.siteConfig.site_catch}</p>
                    <p>サイト説明：{this.state.siteConfig.site_discription}</p>
                    <Button
                      type="button" 
                      className="btn radius px-5 py-2 bg-dark text-white mr-2"
                      onClick={() => {this.props.history.push(`/`)}}
                    >
                        <i className="fa fa-university mr-3"></i>サイトを表示
                    </Button>
                    <Button
                      type="button" 
                      href="#contact"
                      className="btn radius px-5 py-2 bg-dark text-white mr-2"
                      onClick={() => { this.signOut(); }}
                    >
                        <i className="fas fa-sign-out-alt mr-3"></i>サインアウト
                    </Button>
                  </CardBody>
              </Card>
            </Col>
            <Col xs={10} md={4} className="mx-auto mb-2">
              <Card className="dark-shadow">
                <CardHeader className="bg-darkblue text-white">お知らせ
                  </CardHeader>
                  <CardBody
                    onClick={() => { this.menuClick("info"); }}
                    className="bg-grayblue"
                  ><img src={`${process.env.PUBLIC_URL}/information.svg`} alt="mv" className="text-center mx-5 mb-2"/>
                  </CardBody>
              </Card>
            </Col>
            <Col xs={10} md={4} className="mx-auto mb-2">
              <Card className="dark-shadow">
                <CardHeader className="bg-darkblue text-white">サイト情報
                  </CardHeader>
                  <CardBody
                    onClick={() => { this.menuClick("site"); }}
                    className="bg-grayblue"
                  ><img src={`${process.env.PUBLIC_URL}/website.svg`} alt="mv" className="text-center mx-5 mb-2"/>
                  </CardBody>
              </Card>
            </Col>
            <Col xs={10} md={4} className="mx-auto mb-2">
              <Card className="dark-shadow">
                <CardHeader className="bg-darkblue text-white">ブログ
                  </CardHeader>
                  <CardBody
                    onClick={() => { this.menuClick("blog"); }}
                    className="bg-grayblue"
                  ><img src={`${process.env.PUBLIC_URL}/blog.svg`} alt="mv" className="text-center mx-5 mb-2"/>
                  </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} className="mt-5">
              <p className="text-right">
                お問い合わせ<br/>
                <i className="fas fa-envelope-open-text mr-2"></i>info@bloom-system.tech
              </p>
              <p className="text-center small">powerd by bloom system.tech</p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Dashboard;
