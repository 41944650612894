import axios from 'axios';
import env from '../Common/Env';


/**
 * お知らせ一覧取得
 */
const getInfo = async(page,parPage) => {
    return axios.get(`${env.api_domain}/api/v1/info`, {
      params: {
        page: page,
        parPage: parPage
      }
    })
      .then(resp => {
        return resp
      }).catch(err => {
        throw err
      })
  }
  
  /**
   * お知らせ詳細取得
   */
  const getInfoDetail = async(id) => {
    return axios.get(`${env.api_domain}/api/v1/info/${id}`)
      .then(resp => {
        return resp
      }).catch(err => {
        throw err
      })
  }
  
  /**
   * お知らせ詳細登録
   */
  const registInfoDetail = async(title, content, createdAt) => {
    return axios.post(`${env.api_domain}/api/v1/info`, {
      title: title,
      content: content,
      createdAt: createdAt
    },{
      headers: {'Authorization': `bearer ` + localStorage.getItem("token")} 
    })
      .then(resp => {
        return resp
      }).catch(err => {
        throw err
      })
  }
  
  /**
   * お知らせ詳細編集
   */
  const editInfoDetail = async(id, title, content, createdAt) => {
    return axios.put(`${env.api_domain}/api/v1/info`, {
      id: id,
      title: title,
      content: content,
      createdAt: createdAt
    },{
      headers: {'Authorization': `bearer ` + localStorage.getItem("token")} 
    })
      .then(resp => {
        return resp
      }).catch(err => {
        throw err
      })
  }
  
  /**
   * お知らせ詳細削除
   */
  const deleteInfoDetail = async(id) => {
    return axios.delete(`${env.api_domain}/api/v1/info`, {
      headers: {'Authorization': `bearer ` + localStorage.getItem("token")},
      data: { id: id }
    })
      .then(resp => {
        return resp
      }).catch(err => {
        throw err
      })
  }
  
  export { getInfo,getInfoDetail, registInfoDetail, editInfoDetail, deleteInfoDetail }
  