/**
 * サイト情報
 */
export const SITEINFO = {
    SITE_MENU: [
        {URL: "#works", TITLE: "Works"},
        {URL: "#results", TITLE: "Results"},
        {URL: "#contact", TITLE: "Contact"},
    ],
    SITE_SUBMENU: [
        {URL: "/contents", TITLE: "contents"},
    ],
    WORKLIST: ["/01.png","/02.png","/03.png","/04.png"],
    RESULTLIST: [
        {
            title: "Web/Native App",
            data: [
                "大手ECサイト新規開発(JAVA, MySQL)",
                "店舗管理システム保守開発(C# ASP.net, PostgreSQL)",
                "食品系BtoBシステム新規開発(PHP Laravel, JavaScript Vue.js)",
                "電子決済サービス新規開発(Ruby Ruby on Rails)",
                "医薬品系ECサイト新規開発(PHP ECCUBE4)",
                "保育系サービス新規開発(JavaScript React.js)",
                "エンタメ系サービス新規開発(JavaScript ReactNative, Realm)",
            ]
        },
        {
            title: "Business Systems",
            data: [
                "人事考課システム保守開発(C#)",
                "自動運転システム研究開発(C++ Ros, JavaScript React.js)",
                "医療系システム新規開発(JAVA)",
            ]
        },
    ],
    WORKFLOW: [
        {
            title: "発見",
            data: "クライアント様とのヒアリングにより要件の洗い出しとニーズの特定。ワイヤーフレームや要件定義書を用いてソリューションを提示します。",
            image: "/idea.svg"
        },
        {
            title: "設計",
            data: "前工程を元に画面設定書の作成。お客様との認識齟齬を防ぎ、Webシステムの場合はモバイルファーストなサイトをデザインします。※お客様合意の上詳細な設計フェーズを省き工数削減、PDCA重視の対応をさせていただく場合もございます。",
            image: "/layers.svg"
        },
        {
            title: "開発",
            data: "直接的にシステムの機能に左右せずともWebやシステムにおいてUIは非常に重要と考えています。ユーザーエクスペリエンスを重視しつつ、保守管理性能の高い開発を心がけます。",
            image: "/code.svg"
        },
        {
            title: "テスト・デモ環境",
            data: "リリース前にはお客様サーバー配置前にデモ環境にてテストを致します。デモ環境にはお客様にも公開可能なので、公開前にフィードバック管理をスムーズに運ぶことができるよう努めます。",
            image: "/check_list.svg"
        },
        {
            title: "ホスティング・デプロイ",
            data: "レンタルサーバーやVPS、AWS等へのホスティングを致します。Webに精通しておらずご不安な場合には代行してサーバー契約、ドメイン契約なども致します。",
            image: "/cloud_1.svg"
        },
        {
            title: "運用保守",
            data: "買い切りではなく継続的な運用保守を希望していただける場合、Webであればトラフィックの監視、バックアップ、公開後のSEO対策も実施させていただきます。サービスの成長を見守らせて下さい。",
            image: "/web_search_1.svg"
        },
    ],
    SERVICE: [
        {
            title: "IP MEMO",
            data: "SSH,FTP管理デスクトップアプリ。electronによるクロスプラットフォームソフトウェア。各種SSH、FTPなどの接続先情報をローカルに保存。",
            image: "/001.png",
        },
        {
            title: "My Theater",
            data: "映画情報提供管理ウェブアプリ。APIを利用し映画情報の管理、共有ができる映画SNS。PWAに対応",
            image: "/002.png",
        },
        {
            title: "takibi",
            data: "チャットアプリ。焚き火の動画を観ながらどこかの誰かとチャットができるサービス。SOCKET IOのデモAPPとして制作",
            image: "/003.png",
        },
        {
            title: "common func",
            data: "エンジニア向けアプリ。汎用的な関数を閲覧、管理できるサービス",
            image: "/004.png",
        },
        {
            title: "stock word",
            data: "お気に入りの言葉などをメモして管理するアプリ。ReactNativeによるネイティブアプリ",
            image: "/005.png",
        },
    ]
}