import React, { Component } from 'react';
import { Row,Container,Col } from 'reactstrap';

/**
 * 右メイン２カラム２段セクション
 */
class RightColumn2rowsSection extends Component {

  render() {
    return (
      <Container fluid={true} className="wrap bg-second" id="results">
        <Container className="py-5">
          <Row className="py-5">
            <Col xs="12" md="8 mx-auto">
              <p className="text-orange">{this.props.subTitle}</p>
              <h2 className="display-4 en-new-spirit ">{this.props.title}</h2>
              <hr className="dots"/>
              {this.props.lists.map((list, index) => (
                <Row className="mt-5" key={index}>
                  <Col xs="12" sm="4">
                    <h3>{list.title}</h3>
                  </Col>
                  <Col xs="12" sm="8" className="text-left">
                    {list.data.map((d, index) => (
                      <p key={index}>{d}</p>
                    )) }
                  </Col>
                </Row>
              )) }
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}
export default RightColumn2rowsSection;