import React from 'react';

/**
 * Dtリストコンポーネント
 * props
 *  lists = []
 */
const DtListPart = (props) => {
  const lists = props.lists
  return (
    <div>
      <dl className="text-left">
        {lists.map((menu, index) => (
          <dt key={index}>
            <a href={menu.URL} className="text-white">{menu.TITLE}</a>
          </dt>
        ))}
      </dl>
    </div>
  );
}

export default DtListPart;