import axios from 'axios';
import env from '../Common/Env';

/**
 * サイト設定取得
 */
const getSiteConfig = async() => {
  return axios.get(`${env.api_domain}/api/v1/config`)
    .then(resp => {
      resp.data.mainview_path = env.api_domain + '/api/v1/image/' + resp.data.mainview_url
      return resp
    }).catch(err => {
      throw err
    })
}

/**
 * サイト設定更新
 */
const editConfig = async(params) => {
  const param = new FormData();
  param.append('id', params.siteConfig.id);
  param.append('site_name', params.siteConfig.site_name)
  param.append('site_catch', params.siteConfig.site_catch)
  param.append('site_discription', params.siteConfig.site_discription)
  param.append('header_dark', params.siteConfig.header_dark)
  param.append('header_fixed', params.siteConfig.header_fixed)
  param.append('mainview_full', params.siteConfig.mainview_full)
  param.append('mainview_url', params.siteConfig.mainview_url)
  param.append('file', params.img)
  return axios.put(`${env.api_domain}/api/v1/config`, param,{
    headers: {
      'Authorization': `bearer ` + localStorage.getItem("token"),
      'content-type': 'multipart/form-data'
  } 
  })
    .then(resp => {
      return resp
    }).catch(err => {
      throw err
    })
}

export { getSiteConfig,editConfig }
  