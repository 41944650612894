import React, { Component } from 'react';
import { Row,Container,Col } from 'reactstrap';

/**
 * 右メイン２カラムセクション
 */
class RightColumnSection extends Component {

  render() {
    return (
      <Container fluid={true} className="wrap">
        <Container className="py-5">
          <Row className="py-5">
            <Col xs="12" md="8" className="mx-auto">
              <p className="text-orange">{this.props.subTitle}</p>
              <h2 className="display-4 en-new-spirit ">{this.props.title}</h2>
              <hr className="dots"/>
              <Row className="mt-5">
                {this.props.lists.map((list, index) => (
                  <Row key={index}>
                    <Col xs="4">
                      <img src={`${process.env.PUBLIC_URL}${list.image}`} className="img-fluid" alt="mv" />
                    </Col>
                    <Col xs="8 text-left">
                      <h3>{list.title}</h3>
                      <p>{list.data}</p>
                    </Col>
                  </Row>
                )) }
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}
export default RightColumnSection;